<template lang="pug">
.fights
  h1.fights__title Próximas Lutas
  .table
    .table__top
      Button(
        v-if="takeCanEditFights && !editTable"
        text="Alterar Ordem dos Combates"
        @click="doSetTableEdit(true)"
      )

      Button(
        v-if="takeCanEditFights && editTable"
        text="Cancelar Alterações"
        @click="doSaveTable(false)"
      )

      Button(
        v-if="takeCanEditFights && editTable"
        text="Salvar Alterações"
        @click="doSaveTable(true)"
      )

    .table__middle
      Table.content(
        :header="['Lado Azul', 'Lado Vermelho', 'Categoria', 'Arena', 'Horário']"
        :items="takeFights"
      )

      .side(
        v-if="takeCanEditFights || getUser.roles.includes(3)"
      )
        .row

        .row(
          v-for="(row, indexRow) in fights"
        )
          .row__icons.lateral-space(
            v-if="takeCanEditFights && editTable"
          )
              FontAwesomeIcon.icon(
                v-for="index in doTakeAmountOfIcons(indexRow)"
                :key="index"
                :icon="doTakeIcon(index, amountOfIcons[indexRow], indexRow)"
                @click="doTableAction(index, indexRow)"
              )

          .lateral-space.lateral-space--button(
            v-if="doTakeTableText(indexRow) && !editTable"
          )
            Button(
              :text="doTakeTableText(indexRow)"
              @click="doStartFight(indexRow)"
            )

  Modal(
    :visible="showModal"
    @close="doSetModalVisibility"
  )
    .modal-content(
      v-if="step === 0"
    )
      span.modal-content__title Novo horário da luta

      input.modal-content__input.modal-content__input--time(
        type="time"
        id="TimeFight"
        name="Time"
        :min="takeMinTime"
        :max="takeMaxTime"
        v-model="fightTime"
        required
      )

      input.modal-content__input.modal-content__input--date(
        type="date"
        id="DateFight"
        name="Date"
        :min="takeMinDate"
        :max="takeMaxDate"
        v-model="fightDate"
        required
      )

      Button(
        text="Confirmar"
        border="base"
        @click="doSetModalVisibility(true)"
      )

    .modal-content(
      v-if="step === 1 || step === 2"
    )
      span.modal-content.modal-content__title {{takeModalText}}

      Select.modal-content.modal-content__select(
        defaultText="Quantidade posições"
        :options="takeFightOptions"
        type='drop'
        @emit="doSetFightJump"
      )

      Button(
        text="Confirmar"
        border="base"
        @click="doSetModalVisibility(true)"
      )

</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

import {
  Select,
  Button,
  Check
} from '@/components/atoms'

import {
  Modal
} from '@/components/molecules'

import {
  Table
} from '@/components/organisms'

export default {
  name: 'Fights',
  props: {
    // propName: {
    //   type: String,
    //   required: false,
    //   validator: (value) => {
    //     return value
    //   },
    //   default: () => ''
    // }
  },
  data: () => ({
    showModal: false,
    step: 0,
    selectedItemIndex: 0,
    fights: [],
    editTable: false,
    changeNextFights: true,
    fightJump: 0,
    fightDate: '',
    fightTime: '',
    amountOfIcons: []
  }),
  components: {
    Select,
    Button,
    Check,
    Modal,
    Table
  },
  created () {
    this.setFights({ event_id: 1 }).then((res) => {
      this.fights = JSON.parse(JSON.stringify(this.getFights))
    })
  },
  computed: {
    ...mapGetters('users', [
      'getUser'
    ]),
    ...mapGetters('fights', [
      'getFights'
    ]),
    takeFights () {
      let fights = []
      if (this.fights.length) {
        fights = this.fights.map(fight => {
          const final = this.doFormatDateTime(fight.start_at)
          return [fight.blue_side, fight.red_side, fight.category, fight.arena_id, final]
        })
      }
      return fights
    },
    takeCanEditFights () {
      if (this.getUser.roles.includes(4) || this.getUser.roles.includes(5)) {
        return true
      }
      return false
    },
    takeModalText () {
      return this.step === 1 ? 'Adiar em quantas posições?' : 'Adiantar em quantas posições?'
    },
    takeFightOptions () {
      const arr = []
      if (this.step === 1) {
        for (let i = 1; i < this.fights.length - this.selectedItemIndex; i++) {
          arr.push({ text: i.toString(), value: i })
        }
      } else {
        for (let i = 1; (i <= this.selectedItemIndex); i++) {
          arr.push({ text: i.toString(), value: i })
        }
      }
      return arr
    },
    takeMinTime () {
      const date = new Date()
      let hours = date.getHours()
      let minutes = date.getMinutes() + 2
      if (minutes < 10) {
        minutes = `0${minutes}`
      }
      if (minutes > 59) {
        minutes = '00'
      }
      if (hours < 10) {
        hours = `0${hours}`
      }
      return `${hours}:${minutes}`
    },
    takeMaxTime () {
      return '24:00'
    },
    takeMinDate () {
      let today = new Date()
      let dd = today.getDate()
      let mm = today.getMonth() + 1
      const yyyy = today.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      today = yyyy + '-' + mm + '-' + dd
      return today
    },
    takeMaxDate () {
      const date = new Date()
      const day = date.getDate()
      const month = date.getMonth() + 6
      const year = date.getFullYear()

      return `${year}-${month}-${day}`
    }
  },
  methods: {
    ...mapActions('fights', [
      'setFights',
      'setEnterFight',
      'setInitFight',
      'setEnterRoundJudgeFight',
      'setChangeFightsTime'
    ]),
    doFormatDateTime (value) {
      if (!value) {
        return 'Sem horário definido'
      }
      const y = value.split('-')[0]
      const mm = value.split('-')[1]
      const d = value.split('-')[2].split('T')[0]
      const date = d + '/' + mm + '/' + y
      const h = value.split('T')[1].split(':')[0]
      const min = value.split('T')[1].split(':')[1]
      const hour = h + ':' + min
      const final = hour + ' - ' + date
      return final
    },
    doTakeTableText (rowIndex) {
      if (this.fights.length) {
        if (this.getUser.roles.includes(0)) {
          return ''
        } else if (this.getUser.roles.includes(4) && this.fights[rowIndex].round_judge_id && this.fights[rowIndex].round_judge_id === this.getUser.id && !this.fights[rowIndex].ended_at) {
          return 'Continuar' // juiz de round
        } else if (this.getUser.roles.includes(4) && !this.fights[rowIndex].round_judge_id) {
          return 'Iniciar' // juiz de round
        } else if (this.getUser.roles.includes(4) && this.fights[rowIndex].round_judge_id && this.fights[rowIndex].round_judge_id !== this.getUser.id) {
          return 'Entrar' // juiz de round
        } else if (this.getUser.roles.includes(3) && this.fights[rowIndex].round_judge_id) {
          return 'Entrar' // juiz de combate
        }
      }
      return ''
    },
    doEditFight (e) {
      this.step = 0
      this.selectedItemIndex = e
      const final = this.doFormatDateTime(this.fights[e].start_at).split(' - ')[1]
      let date = final.split('/')
      date = date[2] + '-' + date[1] + '-' + date[0]
      this.fightDate = date
      this.fightTime = this.takeMinTime
      this.showModal = true
    },
    doDownFight (e) {
      this.step = 1
      this.selectedItemIndex = e
      this.showModal = true
    },
    doUpFight (e) {
      this.step = 2
      this.selectedItemIndex = e
      this.showModal = true
    },
    doSetFightJump (e) {
      this.fightJump = e.value
    },
    doSetModalVisibility (e) {
      this.showModal = false
      const fights = this.fights
      let meio = ''
      if (!e) {
        return
      }
      if (this.step === 0) {
        this.fights[this.selectedItemIndex].start_at = `${this.fightDate}T${this.fightTime}:00.000Z`
      } else if (this.step === 1) {
        const atual = fights[this.selectedItemIndex]
        meio = atual.start_at
        const novo = fights[this.selectedItemIndex + this.fightJump]
        atual.start_at = novo.start_at
        novo.start_at = meio
        fights[this.selectedItemIndex] = novo
        fights[this.selectedItemIndex + this.fightJump] = atual
      } else {
        const atual = fights[this.selectedItemIndex]
        meio = atual.start_at
        const novo = fights[this.selectedItemIndex - this.fightJump]
        atual.start_at = novo.start_at
        novo.start_at = meio
        fights[this.selectedItemIndex] = novo
        fights[this.selectedItemIndex - this.fightJump] = atual
      }
      this.fights = [...fights]
    },
    doChangeNextFights (e) {
      this.changeNextFights = e
    },
    doSetTableEdit (e) {
      this.editTable = e
    },
    doSaveTable (e) {
      this.editTable = false
      if (e) {
        const newFights = this.fights.filter((fight, index) =>
          fight.start_at !== this.getFights.find(internalFight => internalFight.id === fight.id).start_at
        ).map(fight => {
          return { fight_id: fight.id, new_time: fight.start_at }
        })
        this.setChangeFightsTime(newFights).then(
          resp => this.setFights({ event_id: 1 }).then(resp => {
            this.fights = JSON.parse(JSON.stringify(this.getFights))
          })
        )
        return
      }
      this.fights = JSON.parse(JSON.stringify(this.getFights))
    },
    doStartFight (rowIndex) {
      if (this.getUser.roles.includes(4) && !this.fights[rowIndex].round_judge_id) {
        this.setInitFight({ judge_id: this.getUser.id, fight_id: this.fights[rowIndex].id })
      } else if (this.getUser.roles.includes(4) && this.fights[rowIndex].round_judge_id === this.getUser.id) {
        this.setEnterRoundJudgeFight({ judge_id: this.getUser.id, fight_id: this.fights[rowIndex].id })
      } else if (this.getUser.roles.includes(4) && this.fights[rowIndex].round_judge_id !== this.getUser.id) {
        this.setEnterFight({ judge_id: this.getUser.id, fight_id: this.fights[rowIndex].id })
      } else {
        this.setEnterFight({ judge_id: this.getUser.id, fight_id: this.fights[rowIndex].id })
      }
      this.$router.push('CurrentFight')
    },
    doTakeAmountOfIcons (indexRow) {
      if (indexRow > 0 && indexRow + 1 !== this.fights.length) {
        this.amountOfIcons[indexRow] = 3
        return 3
      } else {
        this.amountOfIcons[indexRow] = 2
        return 2
      }
    },
    doTakeIcon (index, amountOfIcons, indexRow) {
      if (indexRow + 1 === this.fights.length) {
        if (index === 1) {
          return ['fas', 'edit']
        } else if (index === 2) {
          return ['fas', 'chevron-up']
        }
      }

      if (index === 1) {
        return ['fas', 'edit']
      } else if (index === 2) {
        return ['fas', 'chevron-down']
      } else {
        return ['fas', 'chevron-up']
      }
    },
    doTableAction (index, indexRow) {
      if (indexRow + 1 === this.fights.length) {
        if (index === 1) {
          this.doEditFight(indexRow)
          return
        } else if (index === 2) {
          this.doUpFight(indexRow)
          return
        }
      }
      if (index === 1) {
        this.doEditFight(indexRow)
      } else if (index === 2) {
        this.doDownFight(indexRow)
      } else if (indexRow > 0) {
        this.doUpFight(indexRow)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .fights
    .table
      &__top
        display: flex
        align-items: center
        justify-content: space-between
        margin: 8px 96px 8px 0
      &__middle
        display: flex
        .content
          width: 100%
        .side
          display: flex
          flex-direction: column
          .row
            height: 48px
            display: flex
            align-items: center
            justify-content: center
            &__icons
              display: flex
              flex-direction: row
              .icon
                margin: 8px
            .lateral-space
              min-width: 96px
              display: flex
              justify-content: flex-start
              &--button
                justify-content: center

    &__title
      margin: 24px 0
      font-size: 32px
      font-style: normal
      font-weight: 700
      line-height: 72px
      letter-spacing: 0em

    &__edit
      display: flex
      align-items: center
      justify-content: space-between
      margin: 8px 96px 8px 0

    .modal-content
      max-height: 50vh
      overflow-y: scroll
      overflow-x: clip
      display: flex
      flex-direction: column
      align-items: center
      margin: 40px
      width: 100%
      justify-content: space-around

      &__title
        margin: 0
        font-size: 20px
        font-style: normal
        line-height: 30px

      &__input
        min-height: 32px
        margin: 8px
        border: none
        border-bottom: 1px solid #000000
        border-color: #000000

      &__select
        max-height: 30%
        margin: 16px 0 0
        align-items: stretch

</style>
